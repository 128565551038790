<template>
  <div class="background">
    <Notification
      v-if="showNotification && showIfTeamsAreSelected"
      :label="notificationLabel"
      @close="toggleNotification(false)"
    />
    <div class="container">
      <h1 class="no-margin">Wähle die Teams.</h1>
      <div class="container__innner container__inner--active">
        <PlayerCard
          v-for="player in players"
          :key="player.id"
          :player="player"
          class="player-card"
          @click.native="choosePlayer(player), toggleNotification(true)"
        />
      </div>
      <router-link to="./score"
        ><button class="button__score">Spiel starten</button></router-link
      >
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations } from 'vuex'
import PlayerCard from '@/components/PlayerCard.vue'
import Notification from '@/components/Notification.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'teams',
  components: {
    PlayerCard,
    Notification,
    Footer,
  },
  data() {
    return {
      showNotification: false,
    }
  },
  computed: {
    players() {
      return this.$store.state.players
    },
    teams() {
      return this.$store.state.teams
    },
    team_1() {
      return this.teams.team_1
    },
    team_2() {
      return this.teams.team_2
    },
    playerCount() {
      return this.team_1.length + this.team_2.length
    },
    showIfTeamsAreSelected() {
      return this.team_1.length === 2 || this.team_2.length === 2
    },
    notificationLabel() {
      if (this.team_2.length === 2) {
        return 'Team 2'
      } else {
        return 'Team 1'
      }
    },
  },
  methods: {
    ...mapMutations(['addPlayerToTeam']),
    choosePlayer(player) {
      if (this.team_1.length < 2) {
        this.addPlayerToTeam([player, 'team_1'])
      } else if (this.team_2.length < 2) {
        this.addPlayerToTeam([player, 'team_2'])
      }
    },
    toggleNotification(boolean) {
      this.showNotification = boolean
    },
  },
}
</script>

<style>
.player-card {
  width: calc(33% - 17px);
  height: 41%;
  float: left;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

.cancel {
  position: absolute;
  right: 30px;
  top: 30px;
}

.container__innner {
  margin-left: -10px;
  margin-right: -10px;
}

.no-margin {
  margin-bottom: 20px;
}

.margin {
  padding-top: 160px;
}

.button__score {
  background: #fff;
  border: none;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 17px 40px 17px 40px;
  outline: none;
  clear: both;
  float: left;
  margin-top: 60px;
  color: #000;
}
</style>
