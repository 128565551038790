<!-- Diese Komponente wird in "Score" verwendet -->
<template>
  <div class="counter">
    <div class="counter__buttons">
      <PlayerButton
        v-for="(player, playerIndex) in team"
        :key="player.id"
        :player="player"
        :count="countMapped[index].player[playerIndex]"
        @count="countGoals({ team: index, player: playerIndex })"
        class="button--position"
      >
        <span v-if="playerIndex === 0">Verteidigung</span>
        <span v-else>Sturm</span>
      </PlayerButton>
    </div>
    <div class="counter__change" @click="reverseTeam()">
      <img src="../assets/change.svg" />
    </div>
  </div>
</template>

<script>
import PlayerButton from '@/components/PlayerButton.vue'

export default {
  name: 'Counter',
  components: {
    PlayerButton,
  },
  data() {
    return {}
  },
  props: {
    team: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      default: '',
    },
    count: {
      type: Object,
      required: true,
    },
  },
  computed: {
    countMapped: {
      set(value) {
        this.$emit('count', value)
      },
      get() {
        return this.count
      },
    },
  },
  methods: {
    countGoals({ team, player }) {
      const teamSelected = this.countMapped[team]
      const playerSelected = teamSelected.player[player]

      if (teamSelected.total < 7) {
        // Save score for player
        playerSelected.total += 1

        if (player === 0) {
          playerSelected.defense += 1
        } else if (player === 1) {
          playerSelected.offence += 1
        }

        // Save score for team
        teamSelected.total = teamSelected.player
          .map((player) => player.total)
          .reduce((score, total) => total + score, 0)
      } else {
        // Reset all stats
        teamSelected.total = 0
        teamSelected.player.forEach((player) => {
          player.total = 0
          player.defense = 0
          player.offence = 0
        })
      }
    },
    reverseTeam() {
      this.$store.commit('reverseTeam', { team: this.index })
      this.countMapped[this.index].player.reverse()
    },
  },
}
</script>

<style scoped>
.counter {
  display: flex;
  align-items: center;
}

.counter:nth-child(even) {
  flex-direction: row-reverse;
}

.counter__change {
  margin: 0 30px;
}

.counter__buttons {
  display: flex;
  flex-direction: column;
  margin: -20px 0;
}

.counter:nth-child(even) >>> .counter__buttons {
  flex-direction: column-reverse;
}

.button {
  margin: 20px 0;
}
</style>
