<!-- Diese Komponente bildet eine Tabellenzeile für einen Datensatz in "Ranking". Diese wird in "Ranking" durchgeschleift. -->
<template>
    <tr v-if="fields.spiele > 0">
    <td class="table__align">{{ fields.Vorname }} {{ fields.Nachname }}</td>
    <td class="table__highlight">
      {{ fields.scoreRound }}
    </td>
    <td>
      {{ fields.spiele }}
    </td>
    <td>
      {{ fields.siege }}:{{ fields.niederlagen }} ({{ fields.sn_quote_round }})
    </td>
    <td>
      {{ fields.tore }}:{{ fields.gegentore }} ({{ fields.tor_quotient_round }})
    </td>
    <td>{{ fields.knockout }}:{{ fields.knockoutLost }}</td>
    <td>{{ fields.serieMax }} ({{ fields.serie }})</td>
  </tr>
</template>

<script>
export default {
  name: 'PlayerCard',
  props: {
    player: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fields() {
      return this.player.fields
    },
  },
}
</script>

<style scoped>
.table__align {
  text-align: left;
}
</style>
