<template>
  <div class="notification">
    <div class="notification--inner">
      <slot name="text" />
      <router-link v-if="showLink" to="/score" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    label: String,
    showLink: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hidden: false,
      clicked: 0,
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.notification {
  width: 100vw;
  height: 70px;
  position: fixed;
  margin: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #24ff00;
  z-index: 100;
}

.close__small {
  height: 25px;
  width: 25px;
  float: right;
  margin-top: -27px;
  margin-right: 5px;
}

.notification--inner {
  width: 91%;
  margin: 0 auto 0px auto;
}

.notification__text {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  margin-top: 20px;
  margin-left: 5px;
}
</style>
