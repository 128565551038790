<!-- Diese Komponente wird in der Counter Komponente weiter verarbeitet und anschließend im "Score" dargestellt -->
<template>
  <div class="button" @click="countGoals()">
    <p class="button__headline margin__left--20 margin__top--20">
      <span class="button__text--strong">
        {{ player.fields.Vorname }}<br />
      </span>
      <slot />
    </p>
    <div>
      <p class="margin__top--20 margin__left--20 button__text">
        <span class="button__text--strong">{{ count.defense }}</span>
        Verteidigung
      </p>
      <p class="margin__left--20 margin__top--5 button__text">
        <span class="button__text--strong">{{ count.offence }}</span> Sturm
      </p>
      <p class="margin__left--20 margin__top--5 button__text">
        <span class="button__text--strong">{{ count.total }}</span> Total
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerButton',
  props: {
    player: {
      type: Object,
      required: true,
    },
    count: {
      type: Object,
      required: true,
    },
  },
  methods: {
    countGoals() {
      this.$emit('count')
    },
  },
}
</script>

<style scoped>
.button__headline {
  font-size: 24px;
  line-height: 30px;
}
.button__text--strong {
  font-weight: 800;
}
.button__text {
  font-size: 14px;
  color: #888888;
}
.button {
  width: 250px;
  margin: 0;
  height: 190px;
  float: left;
  border: 3px solid #4a4a4a;
  background: none;
  color: #fff;
}
.button:active {
  border: 3px solid #24ff00;
}
</style>
