import Vue from 'vue'
import VueRouter from 'vue-router'
import Teams from '../views/Teams.vue'
import SecondTeam from '../views/Second-Team.vue'
import FirstTeam from '../views/First-Team.vue'
import Score from '../views/Score.vue'
import Start from '../views/Start.vue'
import Ranking from '../views/Ranking.vue'
import Password from '../views/Password.vue'
import storageHelper from 'storage-helper'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'start',
    component: Start,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/score',
    name: 'score',
    component: Score,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/teams',
    name: 'teams',
    component: Teams,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/second-team',
    name: 'secondTeam',
    component: SecondTeam,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/first-team',
    name: 'firstTeam',
    component: FirstTeam,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/ranking',
    name: 'ranking',
    component: Ranking,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/password',
    name: 'password',
    component: Password,
  },

  // {
  // path: "/score",
  // name: "score",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //     component: () =>
  //       import(/* webpackChunkName: "about" */ "../views/Score.vue")
  //   }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!storageHelper.getItem('user-password')) next('/password')
    else next()
  } else next()
})
export default router
