<!-- Diese Komponente wird in "First Team" und "Second Team" verwendet -->
<template>
  <div>
    <Notification>
      <div slot="tags" v-if="this.team.length">
        <Tag
          v-for="player in team"
          :key="player.id"
          :label="player.fields.Vorname + ' ' + player.fields.Nachname"
        />
      </div>
      <p slot="tags" class="notification__text" v-else>{{ teamnote }}</p>
      <PrimaryButton
        slot="text"
        :url="navigateTo"
        v-if="this.team.length === 2"
      />
    </Notification>
    <div class="container">
      <h1 class="no-margin">{{ teamnote }}</h1>
      <div class="container__innner container__inner--active">
        <PlayerCard
          v-for="player in players"
          :key="player.id"
          :player="player"
          class="player-card"
          @click.native="choosePlayer(player), toggleNotification(true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations } from 'vuex'
import PlayerCard from '@/components/PlayerCard.vue'
import Tag from '@/components/Tag.vue'
import Notification from '@/components/Notification.vue'
import PrimaryButton from '@/components/PrimaryButton.vue'

export default {
  name: 'Team',
  components: {
    PlayerCard,
    Notification,
    Tag,
    PrimaryButton,
  },
  data() {
    return {
      showNotification: false,
    }
  },
  props: {
    team: {
      type: Array,
      required: true,
    },
    teamKey: {
      type: String,
      required: true,
    },
    navigateTo: {
      type: String,
      required: true,
    },
    players: {
      type: Array,
      required: true,
    },
    teamnote: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapMutations(['addPlayerToTeam', 'removePlayerFromTeam']),
    choosePlayer(player) {
      const isSelected = this.team.find((entry) => {
        return entry.id === player.id
      })

      if (this.team.length < 2 && !isSelected) {
        this.addPlayerToTeam({ player: player, team: this.teamKey })
      } else {
        console.log(`${player.id} is already selected`)
        this.removePlayerFromTeam({ player: player, team: this.teamKey })
      }
    },
    toggleNotification(boolean) {
      this.showNotification = boolean
    },
  },
}
</script>

<style>
.player-card {
  width: calc(33% - 17px);
  height: 41%;
  float: left;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

.player-card:last-child {
  margin-bottom: 150px;
}

.container__innner {
  margin-left: -10px;
  margin-right: -10px;
}

.no-margin {
  margin-bottom: 20px;
}

.margin {
  padding-top: 160px;
}

.button__score {
  background: #fff;
  border: none;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 17px 40px 17px 40px;
  outline: none;
  clear: both;
  float: left;
  margin-top: 60px;
  color: #000;
}
</style>
