<template>
  <div class="second-team">
    <Team
      :team="$store.state.teams.team_2"
      :players="players"
      team-key="team_2"
      navigateTo="/score"
      teamnote="Wähle Team 2"
    />
  </div>
</template>

<script>
import Team from '@/components/Team.vue'

export default {
  name: 'SecondTeam',
  components: {
    Team,
  },
  computed: {
    players() {
      return this.$store.state.players.filter(
        (x) => !this.$store.state.teams.team_1.includes(x)
      )
    },
  },
}
</script>
