<template>
  <router-link :to="url">
    <Button class="primary-button"> Weiter </Button>
  </router-link>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: {
    url: {
      default: '',
    },
  },
}
</script>

<style scoped>
.primary-button {
  background: #000;
  border: none;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 12px 40px 12px 40px;
  outline: none;
  color: #fff;
  position: absolute;
  right: 4.5%;
  top: 14px;
}
</style>
