<template>
  <div class="score" ontouchstart>
    <video
      v-if="
        (showNotification && this.count.team_1.total == 0) ||
          (showNotification && this.count.team_2.total == 0)
      "
      id="goal"
      autoplay
    >
      <source src="../assets/video.mp4" type="video/mp4" />
    </video>
    <NotificationSuccess>
      <p slot="text" v-if="showNotification" class="notification__text">
        Speichern erfolgreich. Starte jetzt ein neues Spiel.
      </p>
      <div slot="text" v-else-if="gameCanEnd">
        <p class="notification__text">Trage das Spielergebnis ein.</p>
        <PrimaryButton class="next-game" @click.native="updatePlayers()" />
      </div>
      <p slot="text" v-else class="notification__text">
        Trage das Spielergebnis ein.
      </p>
    </NotificationSuccess>
    <div class="counter__ranking">
      <p class="counter__text">
        {{ count.team_1.total }} : {{ count.team_2.total }}
      </p>
    </div>

    <div class="score__counter">
      <Counter
        v-for="(team, index) in teams"
        :key="index"
        :team="team"
        :index="index"
        :count="count"
        @count="saveCount($event)"
      />
    </div>
  </div>
</template>

<script>
import Counter from '@/components/Counter.vue'
import PrimaryButton from '@/components/PrimaryButton.vue'
import NotificationSuccess from '@/components/NotificationSuccess.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'score',
  components: {
    Counter,
    PrimaryButton,
    NotificationSuccess
  },
  data() {
    return {
      showNotification: false,
      count: {
        team_1: {
          total: 0,
          player: [
            {
              defense: 0,
              offence: 0,
              total: 0
            },
            {
              defense: 0,
              offence: 0,
              total: 0
            }
          ]
        },
        team_2: {
          total: 0,
          player: [
            {
              defense: 0,
              offence: 0,
              total: 0
            },
            {
              defense: 0,
              offence: 0,
              total: 0
            }
          ]
        }
      }
    }
  },
  computed: {
    players() {
      return this.$store.state.players
    },
    teams() {
      return this.$store.state.teams
    },
    totalScore() {
      return Object.values(this.count).reduce(
        (score, num) => score.total + num.total
      )
    },
    gameCanEnd() {
      const difference = Math.abs(
        this.count.team_1.total - this.count.team_2.total
      )

      if (
        (this.count.team_1.total >= 6 || this.count.team_2.total >= 6) &&
        (difference > 1 || this.totalScore > 12) &&
        difference <= 6
      ) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions(['patchPlayers', 'fetchPlayers']),
    ...mapMutations(['updatePlayer', 'removeAllPlayers']),
    saveCount(count) {
      this.count = count
    },
    rebuildFields({ player, won, countWinner, countLoser, index }) {
      const fieldsCurrent = player.fields
      const fieldsUpdated = {}

      console.log('winner', countWinner, 'loser', countLoser)
      let loserTeam = countLoser.player[0].total + countLoser.player[1].total

      // Global
      // fieldsUpdated.spiele = fieldsCurrent.spiele + 1
      if (won) {
        // Winner
        fieldsUpdated.siege = fieldsCurrent.siege + 1
        fieldsUpdated.serie = fieldsCurrent.serie + 1
        fieldsUpdated.serieMax = fieldsCurrent.serie === fieldsCurrent.serieMax ? fieldsCurrent.serieMax += 1 : fieldsCurrent.serieMax
        fieldsUpdated.knockout = loserTeam === 0 ? fieldsCurrent.knockout += 1 : fieldsCurrent.knockout
        fieldsUpdated.tore = fieldsCurrent.tore + (countWinner.player[0].total + countWinner.player[1].total)
        fieldsUpdated.gegentore = fieldsCurrent.gegentore + (countLoser.player[0].total + countLoser.player[1].total)
        fieldsUpdated.toreDef = fieldsCurrent.toreDef + countWinner.player[index].defense
        fieldsUpdated.toreOff = fieldsCurrent.toreOff + countWinner.player[index].offence

      } else {
        // Loser
        fieldsUpdated.niederlagen = fieldsCurrent.niederlagen + 1
        fieldsUpdated.serie = fieldsCurrent.serie = 0
        fieldsUpdated.knockoutLost = loserTeam === 0 ? fieldsCurrent.knockoutLost += 1 : fieldsCurrent.knockoutLost
        fieldsUpdated.tore = fieldsCurrent.tore + (countLoser.player[0].total + countLoser.player[1].total)
        fieldsUpdated.gegentore = fieldsCurrent.gegentore + (countWinner.player[0].total + countWinner.player[1].total)
        fieldsUpdated.toreDef = fieldsCurrent.toreDef + countLoser.player[index].defense
        fieldsUpdated.toreOff = fieldsCurrent.toreOff + countLoser.player[index].offence
      }

      return fieldsUpdated
    },
    async updatePlayers() {
      // Define winner and loser
      let winner = {}
      let loser = {}

      if (this.count.team_1.total > this.count.team_2.total) {
        winner = { team: [...this.teams.team_1], count: this.count.team_1 }
        loser = { team: [...this.teams.team_2], count: this.count.team_2 }
      } else {
        winner = { team: [...this.teams.team_2], count: this.count.team_2 }
        loser = { team: [...this.teams.team_1], count: this.count.team_1 }
      }

      // Update players in each team
      for (const i in winner.team) {
        const player = winner.team[i]
        player.fields = await this.rebuildFields({
          player,
          won: true,
          countWinner: winner.count,
          countLoser: loser.count,
          index: i
        })
      }

      for (const i in loser.team) {
        const player = loser.team[i]
        player.fields = await this.rebuildFields({
          player,
          won: false,
          countWinner: winner.count,
          countLoser: loser.count,
          index: i
        })
      }

      // Patch players
      const players = [...winner.team, ...loser.team]
      await this.patchPlayers(players)

      this.showNotification = true
      await this.fetchPlayers({ field: 'score', direction: 'desc' })
     // this.removeAllPlayers()
      //this.$router.push('/first-team')
    }
  }
}
</script>

<style>
.next-game {
  float: right;
  margin-top: 90px;
}

.finish-game {
  float: left;
  margin-top: 90px;
}

.notification__text {
  position: absolute;
  color: #000;
}

.score__counter {
  position: absolute;
  top: 150px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}

.counter__ranking {
  position: absolute;
  top: 320px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter__text {
  font-size: 50px;
  color: #fff;
  text-align: center;
  font-weight: 800;
}

.counter__change--left {
  float: left;
  margin-top: 190px;
  margin-left: 40px;
}
.counter__change--right {
  float: right;
  margin-top: 190px;
  margin-right: 40px;
}
</style>
