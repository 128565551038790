<template>
  <div class="notification" v-if="hidden == false" @click="$emit('close')">
    <div class="notification--inner">
      <slot name="text" class="notification__text" />
      <slot name="tags" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    label: String,
    showLink: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hidden: false,
    }
  },
}
</script>

<style scoped>
.notification {
  width: 100vw;
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #24ff00;
  z-index: 100;
}
.notification--inner {
  width: 91%;
  margin: 0 auto 0px auto;
}
</style>
