<template>
  <div>
    <div class="container">
      <!-- Sektion: Globale Zahlen -->
      <div>
        <h1>Zahlen und Fakten</h1>
        <Numbers :sort="{ field: 'spiele', direction: 'asc' }" />
      </div>
      <!-- Sektion: Typografische Statements -->
      <div class="facts--max-width">
        <h4 class="margin__bottom--30 clear">Aktuelle Informationen</h4>
        <Facts :sort="{ field: 'score', direction: 'desc' }" v-slot="slotProps">
          <p>
            {{ slotProps.fields.Vorname }} führt die Tabelle mit
            {{ slotProps.fields.scoreRound }} Punkten an. Seine S/N Quote ist
            aktuell bei {{ slotProps.fields.sn_quote_round }}.
          </p>
        </Facts>
        <hr class="line" />
        <Facts
          :sort="{ field: 'serie', direction: 'desc' }"
          v-slot="spiele"
          v-if="fields.serie > 2"
        >
          <p>
            {{ spiele.fields.Vorname }} ist on fire! Er hat die letzten
            {{ spiele.fields.serie }} Spiele gewonnen. Fordere ihn heraus und
            beende seine Serie.
          </p>
        </Facts>
        <hr class="line" v-if="fields.serie > 3" />
        <Facts
          :sort="{ field: 'tor_anteil', direction: 'desc' }"
          v-slot="anteil"
        >
          <p>
            Der beste Torschütze ist momentan {{ anteil.fields.Vorname }}. Er
            erzielt pro Spiel durchschnittlich
            {{ anteil.fields.tor_anteil_round }} Tore.
          </p>
        </Facts>
        <hr class="line" />
        <Facts :sort="{ field: 'knockout', direction: 'desc' }" v-slot="kasten">
          <p>
            {{ kasten.fields.Vorname }} hat den größten Bierdurst! Er hat
            bereits {{ kasten.fields.knockout }} Kästen Bier erspielt.
          </p>
        </Facts>
      </div>
      <!-- Sektion: Tabelle -->
      <h4 class="margin__bottom--30">Die Tabelle</h4>
      <div class="table-scrollable">
        <table>
          <tr>
            <th class="table__name-column">Name</th>
            <th class="table__column--small table__highlight">Score</th>
            <th class="table__column--small">Spiele</th>
            <th>Sieg / Niederlage</th>
            <th>Tore</th>
            <th class="table__column--small">
              <img src="../assets/beer.svg" class="table__icon" />
            </th>
            <th>Max. Serie</th>
          </tr>
          <Data v-for="player in players" :key="player.id" :player="player" />
        </table>
      </div>
      <!-- Sektion: Tabelle individual -->
      <h4 class="margin__bottom--30">
        Torschützen-Wertung (Ø Anzahl an Toren pro Spiel)
      </h4>
      <div class="table-scrollable">
        <table>
          <tr>
            <th class="table__name-column">Name</th>
            <th class="table__column--small">Gesamt</th>
            <th class="table__column--small">Verteidigung</th>
            <th class="table__column--small">Sturm</th>
          </tr>
          <DataIndividual
            v-for="player in playersSortedByTorAnteilRound"
            :key="player.id"
            :player="player"
            :sort="{ field: 'spiele', direction: 'desc' }"
          />
        </table>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Data from '@/components/Data.vue'
import DataIndividual from '@/components/DataIndividual.vue'
import Facts from '@/components/Facts.vue'
import Numbers from '@/components/Numbers.vue'

export default {
  name: 'teams',
  components: {
    Footer,
    Data,
    Facts,
    Numbers,
    DataIndividual,
  },
  computed: {
    players() {
      return this.$store.state.players
    },
    playersSortedByTorAnteilRound() {
      return [...this.players].sort((a, b) => {
        return b.fields.tor_anteil_round - a.fields.tor_anteil_round
      })
    },
    fields() {
      if (!this.player) return {}
      return this.player.fields
    },
  },
}
</script>

<style scoped>
.table__icon {
  width: 12px;
}
.facts--max-width {
  max-width: 1000px;
}
</style>
