<!-- Diese Komponente zeigt die globalen Zahlen auf der Seite "Ranking" -->
<template>
  <div>
    <div class="numbers__column">
      <h2 class="numbers">{{ fields.SpieleGlobalRound }}</h2>
      <p class="numbers__caption">Spiele</p>
    </div>
    <div class="numbers__column">
      <h2 class="numbers">{{ fields.ToreGlobalRound }}</h2>
      <p class="numbers__caption">Tore</p>
    </div>
    <div class="numbers__column--last">
      <h2 class="numbers">{{ fields.knockoutGlobalRound }}</h2>
      <img src="../assets/beer.svg" class="numbers__beer-icon--large" />
      <p class="numbers__caption">Kästen</p>
    </div>
  </div>
</template>

<script>
var Airtable = require('airtable')
var base = new Airtable({ apiKey: process.env.VUE_APP_AIRTABLE }).base(
  'appoz1QmkROB8PjgM'
)

export default {
  name: 'Facts',
  props: {
    sort: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      players: [],
    }
  },
  computed: {
    player() {
      return this.players[0]
    },
    fields() {
      if (!this.player) return {}
      return this.player.fields
    },
  },
  mounted() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      let players = []
      base('Spieler')
        .select({ sort: [this.sort] })
        .all()
        .then((records) => {
          records.forEach(function (record) {
            players = [...players, { id: record.id, fields: record.fields }]
          })
          this.players = players
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.numbers {
  font-size: 90px;
  letter-spacing: 1px;
  color: #fff;
  float: left;
}
.numbers__column {
  width: 30%;
  max-width: 320px;
  float: left;
}
.numbers__column--last {
  width: 30%;
  max-width: 320px;
  float: left;
  margin-bottom: 90px;
  margin-left: 40px;
}
.numbers__caption {
  color: #fff;
  clear: both;
}
.numbers__beer-icon--large {
  height: 55px;
  float: left;
  margin-top: 25px;
  margin-left: 15px;
}


@media (max-width: 600px) {
.numbers__column {
  width: 50%;
  max-width: 320px;
  margin-top: 10px;
  clear: both;
}
.numbers__column--last {
  width: 50%;
  max-width: 320px;
  clear: both;
  margin-bottom: 90px;
  margin-top: 10px;
  margin-left: 0;
}
}
</style>
