import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

var Airtable = require('airtable')
var base = new Airtable({ apiKey: process.env.VUE_APP_AIRTABLE }).base(
  'appoz1QmkROB8PjgM'
)

export default new Vuex.Store({
  state: {
    players: [],
    teams: {
      team_1: [],
      team_2: [],
    },
  },
  mutations: {
    savePlayers(state, payload) {
      // const sort
      payload.sort(function (a, b) {
        return b.fields.score - a.fields.score
      })
      state.players = payload
    },
    addPlayerToTeam(state, { player, team }) {
      state.teams[team] = [...state.teams[team], player]
    },
    removePlayerFromTeam(state, { player, team }) {
      let selectedTeam = state.teams[team]
      state.teams[team] = selectedTeam.filter((entry) => {
        return entry.id !== player.id
      })
    },
    reverseTeam(state, { team }) {
      state.teams[team].reverse()
    },
    removeAllPlayers(state) {
      state.teams = { team_1: [], team_2: [] }
    },
    updatePlayer(state, payload) {
      const player = payload[0]
      const team = payload[1]
      const index = state.teams[team].findIndex(
        (entry) => entry.id === player.id
      )

      if (index) state.teams[team][index] = player
    },
  },
  actions: {
    fetchPlayers(context, select) {
      let players = []
      base('Spieler')
        .select(select)
        .all()
        .then((records) => {
          records.forEach(function (record) {
            // Call mutation to save player records
            players = [...players, { id: record.id, fields: record.fields }]
          })
          return context.commit('savePlayers', players)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    patchPlayers(context, payload) {
      const players = payload
      base('Spieler').update(players, function (err, records) {
        if (err) {
          console.error(err)
          return
        }
        return records
      })
    },
  },
  modules: {},
})
