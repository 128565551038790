<template>
  <div class="tag">
    <span> {{ label }} </span>
  </div>
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.tag {
  background: none;
  height: 35px;
  border: 2px solid #000;
  border-radius: 20px;
  padding: 8px 25px 0px 25px;
  text-decoration: none;
  font-size: 16px;
  line-height: 16px;
  color: #000;
  float: left;
  margin-top: 18px;
  margin-right: 20px;
}

.tag--inner {
  float: left;
}
</style>
