<template>
  <div class="first-team">
    <Team
      :team="$store.state.teams.team_1"
      :players="players"
      team-key="team_1"
      navigateTo="/second-team"
      teamnote="Wähle Team 1"
    />
  </div>
</template>

<script>
import Team from '@/components/Team.vue'

export default {
  name: 'FirstTeam',
  components: {
    Team,
  },
  computed: {
    players() {
      return this.$store.state.players
    },
  },
}
</script>
