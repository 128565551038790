<!-- Diese Komponente bildet eine Tabellenzeile für einen Datensatz in "Ranking". Diese wird in "Ranking" durchgeschleift. -->
<template>
    <tr v-if="fields.spiele > 0">
    <td class="table__align">{{ fields.Vorname }} {{ fields.Nachname }}</td>
    <td>
      {{ fields.tor_anteil_round }}
    </td>
    <td>
      {{ fields.tor_anteil_def }}
    </td>
    <td>
      {{ fields.tor_anteil_off }}
    </td>
  </tr>
</template>

<script>
export default {
  name: 'PlayerCard',
  props: {
    player: {
      type: Object,
      default: () => {},
    },
    sort: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fields() {
      return this.player.fields
    },
  },
}
</script>

<style scoped>
.table__align {
  text-align: left;
}
</style>
