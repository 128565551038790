<!-- Diese Komponente bildet die Grundlage für die typografischen Statements auf der Tabellen Seite -->
<template>
  <div>
    <p class="facts__text margin__bottom--30">
      <slot :fields="fields" />
    </p>
  </div>
</template>

<script>
var Airtable = require('airtable')
var base = new Airtable({ apiKey: process.env.VUE_APP_AIRTABLE }).base(
  'appoz1QmkROB8PjgM'
)

export default {
  name: 'Facts',
  props: {
    sort: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      players: [],
    }
  },
  computed: {
    player() {
      return this.players[0]
    },
    fields() {
      if (!this.player) return {}
      return this.player.fields
    },
  },
  mounted() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      let players = []
      base('Spieler')
        .select({ sort: [this.sort] })
        .all()
        .then((records) => {
          records.forEach(function (record) {
            players = [...players, { id: record.id, fields: record.fields }]
          })
          this.players = players
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
</script>

<style scoped>
.facts__text {
  font-size: 40px;
  color: #fff;
  line-height: 50px;
  font-weight: 500;
}
@media (max-width: 600px) {
    .facts__text {
  font-size: 28px;
  color: #fff;
  line-height: 36px;
  font-weight: 500;
}
}
</style>
