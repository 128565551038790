<template>
  <div id="app">
    <Header class="container" />
    <router-view />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  components: {
    Header,
  },
  beforeCreate() {
    this.$store.dispatch('fetchPlayers', { field: 'score', direction: 'desc' })
  },
}
</script>
