<!-- Diese Komponente wird in "Team" eingesetzt -->
<template>
  <div class="player" @click="$emit('test', player.fields)">
    <div class="player__inner">
      <div class="player__top">
        <div :class="{ active: isActive }" class="player__img">
          <img v-if="portrait" :src="portrait" />
          <img v-else src="../assets/profil-bild.jpg" />
        </div>
        <div class="player__head">
          <div class="player__score">{{ fields.scoreRound }}</div>
          <div class="player__nation" :class="fields.nation">
            <img v-if="badge" class="player__badge" :src="badge" />
          </div>
          <div class="player__name">
            {{ fields.Vorname }} {{ fields.Nachname }}
          </div>
        </div>
      </div>
      <div class="auswertung">
        <div class="auswertung__spalte">
          <p class="auswertung__zahl">{{ fields.spiele }}</p>
          <p class="clear auswertung__text">Spiele</p>
        </div>
        <div class="auswertung__spalte">
          <p class="auswertung__zahl">{{ fields.sn_quote_round }}</p>
          <p class="clear auswertung__text">S/N Quote</p>
        </div>
        <div class="auswertung__spalte">
          <p class="auswertung__zahl">{{ fields.tor_quotient_round }}</p>
          <p class="clear auswertung__text">Torquotient</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerCard',
  props: {
    player: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fields() {
      return this.player.fields
    },
    isActive() {
      const selectedPlayers = [
        ...this.$store.state.teams.team_1,
        ...this.$store.state.teams.team_2,
      ]

      const isActive = selectedPlayers.find((entry) => {
        return entry === this.player
      })

      return isActive
    },
    wert() {
      return this.playerCount
    },
    portrait() {
      if (this.fields.profilbild && this.fields.profilbild.length) {
        return this.fields.profilbild[0].url
      } else {
        return false
      }
    },
    badge() {
      if (this.fields.badge && this.fields.badge.length) {
        return this.fields.badge[0].url
      } else {
        return false
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.player__badge {
  height: 33px !important;
  float: left;
  margin-left: 55px;
}
.player {
  background: black;
  position: relative;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
}

.player__inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.player__top {
  position: relative;
  height: 30.3vw;
}

.player__head {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  height: 100%;
  padding: 20px;
}

.player__score {
  margin-bottom: auto;
  float: left;
  font-size: 42px;
  line-height: 50px;
  font-weight: bold;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.8);
}

.player__nation {
  width: 45px;
  height: 34px;
  border: 1px solid #888;
  margin-bottom: 5px;
  background-size: contain;
}

.player__img {
  top: 0;
  width: 100%;
  height: 100%;
  border: white 2px solid;
}

.player__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0%;
}

.player__img.active {
  border: #24ff00 2px solid;
}

.auswertung {
  display: flex;
  justify-content: space-between;
  color: white;
  margin-top: 25px;
  margin-bottom: 5px;
}

.player__name {
  font-size: 20px;
  color: #fff;
  letter-spacing: 1px;
  margin: 5px 0 0 0;
  font-family: Univers;
  font-weight: 800;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.8);
}

.auswertung p {
  margin-left: 25px;
  margin-top: 0;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

.auswertung__zahl {
  font-size: 20px;
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  margin-top: 0;
  margin-bottom: 5px;
  font-family: Univers;
  font-weight: 800;
}

.auswertung__text {
  color: #888;
  letter-spacing: 1px;
  text-align: center;
  font-size: 12px;
}

/* Nationen – Klassen werden in der Airtable mitgegeben */

.deutschland {
  background-image: url('../assets/deutschland.jpg');
}

.frankreich {
  background-image: url('../assets/frankreich.jpg');
  width: 45px;
  height: 34px;
}

.schweiz {
  background-image: url('../assets/schweiz.jpg');
}

.bosnien {
  background-image: url('../assets/bosnien.jpg');
  width: 68px;
}

.bayern {
  background-image: url('../assets/bayern.jpg');
}

.hessen {
  background-image: url('../assets/hessen.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
