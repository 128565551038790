<!-- Passwort Schutz für Applikation. Passwort muss in .env hinterlegt sein und ggf. bei Hostinganbieter hinterlegt werden -->
<template>
  <div>
    <div class="container">
      <form v-on:submit.prevent="validateBeforeSubmit">
        <div>
          <label>Login</label>
          <input
            autofocus
            class="form-control password-field"
            type="password"
            name="password"
            v-model.trim="password"
            placeholder="Passwort"
          />
          <span class="error help-block"></span>
        </div>
        <div class="text-danger" v-if="error">
          <p>Dieses Passwort ist nicht korrekt.</p>
        </div>
        <button class="login-button" type="submit">Anmelden</button>
      </form>
    </div>
  </div>
</template>

<script>
import storageHelper from 'storage-helper'
export default {
  data() {
    return {
      error: null,
      password: null,
    }
  },

  methods: {
    validateBeforeSubmit() {
      if (this.password === process.env.VUE_APP_AUTHKEY) {
        this.error = false
        storageHelper.setItem('user-password', this.password)
        this.$router.push('first-team')
      } else {
        this.error = true
      }
    },
  },
}
</script>

<style scoped>
.login-button {
  background: #fff;
  border: none;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 20px 103px 20px 103px;
  outline: none;
  color: #000;
  float: left;
  clear: both;
  margin-top: 30px;
}

.formular {
  float: left;
  width: 30%;
}

input {
  height: 60px;
  width: 300px;
  border: 1px solid #d8d8d8;
  font-size: 20px;
  outline: none;
  background: none;
  padding-left: 20px;
  color: #fff;
  display: block;
  float: left;
  clear: both;
  margin-top: 70px;
  border-radius: 0;
}

label {
  float: left;
  font-size: 46px;
  line-height: 54px;
  font-weight: bold;
  color: #fff;
}

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 23vh;
}

.text-danger {
  color: #fff;
  float: left;
  margin-top: 15px;
}
</style>
